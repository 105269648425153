<template>
  <div class="multiview-event-drawer">
    <div class="multiview-event-drawer__header">
      <div
        v-show="!isLoading"
        class="multiview-event-drawer__header-heading"
      >
        {{ heading }}
      </div>
      <div
        v-show="!isLoading"
        class="multiview-event-drawer__header-subheading"
      >
        {{ subheading }}
        <div
          v-if="isLimitedOffer"
          class="limited-offer-indicator"
        >
          <span class="divider">
            •
          </span>
          <div class="indicator-content">
            Limited offer
          </div>
        </div>
      </div>
      <button
        class="multiview-event-drawer__header-button"
        @click="closeDrawer"
      >
        <Icon
          name="x"
        />
      </button>
    </div>
    <div
      v-if="isLoading"
      class="multiview-event-drawer__loader"
    >
      <Spinner />
    </div>
    <div
      v-else-if="hasLoaded"
      class="multiview-event-drawer__content"
      :class="{'game-params-active': activeTab === eventDrawerTabTypes.GAME_PARAMS }"
    >
      <div class="multiview-event-drawer__tabs">
        <div
          :class="[
            'multiview-event-drawer__tabs-item',
            { 'multiview-event-drawer__tabs-item--is-active': activeTab === eventDrawerTabTypes.INFO }
          ]"
          @click="setActiveTab(eventDrawerTabTypes.INFO)"
        >
          <Icon name="info" />
          Info
        </div>
        <div
          :class="[
            'multiview-event-drawer__tabs-item',
            { 'multiview-event-drawer__tabs-item--is-active': activeTab === eventDrawerTabTypes.STATS }
          ]"
          @click="setActiveTab(eventDrawerTabTypes.STATS)"
        >
          <Icon name="bar-chart" />
          Statistics
        </div>
        <div
          :class="[
            'multiview-event-drawer__tabs-item',
            { 'multiview-event-drawer__tabs-item--is-active': activeTab === eventDrawerTabTypes.MARKETS }
          ]"
          @click="setActiveTab(eventDrawerTabTypes.MARKETS)"
        >
          <Icon name="list" />
          Markets
        </div>
        <div
          v-if="eventDrawerTabTypes.GAME_PARAMS"
          :class="[
            'multiview-event-drawer__tabs-item',
            { 'multiview-event-drawer__tabs-item--is-active': activeTab === eventDrawerTabTypes.GAME_PARAMS }
          ]"
          @click="setActiveTab(eventDrawerTabTypes.GAME_PARAMS)"
        >
          <Icon name="sliders" />
          Game parameters
        </div>
        <div
          v-if="eventDrawerTabTypes.PLAYER_PARAMS"
          :class="[
            'multiview-event-drawer__tabs-item',
            { 'multiview-event-drawer__tabs-item--is-active': activeTab === eventDrawerTabTypes.PLAYER_PARAMS }
          ]"
          @click="setActiveTab(eventDrawerTabTypes.PLAYER_PARAMS)"
        >
          <Icon name="user-03" />
          Player parameters
        </div>
      </div>
      <InfoTab
        v-if="activeTab === eventDrawerTabTypes.INFO"
        :event="event"
        :disabled="isProcessing"
      />
      <StatisticsTab
        v-else-if="activeTab === eventDrawerTabTypes.STATS"
        :event="event"
        :disabled="isProcessing"
      />
      <MarketsTab
        v-else-if="activeTab === eventDrawerTabTypes.MARKETS"
        :event="event"
        :disabled="isProcessing"
      />
      <GameParametersTab
        v-else-if="activeTab === eventDrawerTabTypes.GAME_PARAMS"
        :event="event"
        :disabled="isProcessing"
      />
      <PlayerParametersTab
        v-else-if="activeTab === eventDrawerTabTypes.PLAYER_PARAMS"
        :event="event"
        :disabled="isProcessing"
      />
      <div
        v-else
        class="multiview-event-drawer__tab-placeholder"
      />
    </div>
    <div class="multiview-event-drawer__footer">
      <div
        v-if="isProcessing"
        class="multiview-event-drawer__updater"
      >
        <Spinner
          small
        />
        Updating...
      </div>
      <Button
        variant="secondary"
        :icon="'external'"
        class="audit-log-button"
        @click="openAuditLog"
        v-if="auditLogEnabled"
      >
        Visit Audit log
      </Button>
      <Button
        variant="primary"
        icon="link-external"
        @click="openSingleView"
      >
        Open single view
      </Button>
    </div>
  </div>
</template>

<script>
import {
  computed, watch, inject, onMounted, onBeforeUnmount,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import {
  pickBy, identity, toUpper, toLower, cloneDeep,
} from 'lodash';
import { useOnlineStatus } from '@/composables';
import {
  getEventDrawerTabTypes, parseEventModel, makeEventHeading, makeEventSubheading,
} from '@/services/helpers/event-drawer';
import Icon from '@/components/common/Icon';
import Spinner from '@/components/common/Spinner';
import Button from '@/components/common/Button';
import { getSuperAdminData } from '@/services/helpers/super-admin';
import InfoTab from './InfoTab';
import StatisticsTab from './StatisticsTab';
import MarketsTab from './MarketsTab';
import PlayerParametersTab from './PlayerParametersTab';
import GameParametersTab from './GameParametersTab';

export default {
  components: {
    Icon,
    Spinner,
    Button,
    InfoTab,
    StatisticsTab,
    MarketsTab,
    PlayerParametersTab,
    GameParametersTab,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const eventId = computed(() => route.query.event);
    const event = computed(() => parseEventModel(store.getters.multiviewDrawerEvent));
    const multiviewListEvent = computed(() => store.getters.getSingleMultiviewListEvent(eventId.value));
    const multiviewEventParams = computed(() => multiviewListEvent.value?.params);
    const isLimitedOffer = computed(() => multiviewEventParams.value?.selectedMarkets?.limitedOffer);
    const isLoading = computed(() => store.getters.multiviewDrawerLoading);
    const isProcessing = computed(() => store.getters.multiviewDrawerProcessing);
    const hasLoaded = computed(() => !!event.value);
    const heading = computed(() => makeEventHeading(event.value || {}));
    const subheading = computed(() => makeEventSubheading(event.value || {}));
    const eventDrawerTabTypes = computed(() => getEventDrawerTabTypes());
    const activeTab = computed(() => eventDrawerTabTypes.value[toUpper(route.query.tab)]);

    const setActiveTab = (tabType) => {
      router.replace({
        name: route.name,
        query: pickBy({
          ...route.query,
          tab: toLower(tabType),
        }, identity),
      });
    };
    const openSingleView = () => {
      const routeData = router.resolve({
        name: 'trading-ui',
        params: { eventId: eventId.value },
      });
      window.open(routeData.href, '_blank');
    };
    const closeDrawer = inject('closeDrawer');

    const { isSuperAdmin } = getSuperAdminData();
    const auditLogEnabled = computed(() => isSuperAdmin);

    const openAuditLog = () => {
      const routeOptions = {
        name: 'audit-log',
        params: {
          eventId: eventId.value,
        },
      };

      router.push(routeOptions);
    };

    watch(eventId, (newEventId, oldEventId) => {
      if (!event.value && !newEventId && oldEventId) {
        store.dispatch('clearMultiviewDrawer', oldEventId);
        return;
      }
      if (route.name !== 'trading-ui-multiview' || !eventId.value) {
        store.dispatch('clearMultiviewDrawer');
        return;
      }
      store.dispatch('loadMultiviewDrawer', eventId.value);
    }, {
      immediate: true,
    });

    onMounted(() => {
      if (route.query.tab && !eventDrawerTabTypes.value[toUpper(route.query.tab)]) {
        const query = cloneDeep(route.query);
        delete query.tab;
        router.replace({
          name: route.name,
          query: pickBy(query, identity),
        });
      }
    });
    onBeforeUnmount(() => {
      store.dispatch('clearMultiviewDrawer', event.value?.eventId);
    });

    useOnlineStatus({
      onReconnect() {
        store.dispatch('reloadMultiviewDrawer');
      },
    });

    return {
      eventDrawerTabTypes,
      event,
      isLoading,
      isProcessing,
      hasLoaded,
      heading,
      subheading,
      activeTab,
      setActiveTab,
      openSingleView,
      closeDrawer,
      auditLogEnabled,
      openAuditLog,
      isLimitedOffer,
    };
  },
};
</script>

<style lang="scss">
.multiview-event-drawer {
  position: absolute;
  top: -8px;
  right: 0;
  width: 587px;
  height: calc(100% + 8px);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  z-index: $eventDetailsZIndex;
  border-left: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
  background: var(--neutral-bg-default-resting, #FFF);
  box-shadow: 0px 2px 4px 0px rgba(25, 20, 20, 0.08);

  &__loader {
    flex: 1;
    width: 100%;
    height: 100%;
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr 32px;
    grid-template-rows: 24px 14px;
    gap: 4px;
    padding: 16px;
    border-bottom: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
    flex-shrink: 0;

    &-heading {
      overflow: hidden;
      color: var(--neutral-text-default-resting, #191414);
      text-overflow: ellipsis;
      font-family: Poppins;
      font-size: 15.72px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      grid-row: 1;
      grid-column: 1;
    }

    &-subheading {
      color: var(--neutral-text-medium-resting, #6D6D6D);
      font-family: Rubik;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      grid-row: 2;
      grid-column: 1;

      .limited-offer-indicator {
        display: inline-block;
        .indicator-content {
          display: inline-block;
          font-size: 10px;
          padding: 2px;
          border-radius: 2px;
          background-color: #E8EFFF;
          color: #0E3999;
        }
      }
    }

    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-row: 1 / 3;
      grid-column: 2;
      width: 32px;
      height: 32px;
      padding: 8px;
      cursor: pointer;

      .icon svg path {
        stroke: #191414;
      }
    }
  }

  &__content {
    flex: 1;
    overflow-y: auto;
    width: 100%;
    height: calc(100% - 75px - 64px);

    &.game-params-active {
      overflow: visible;
    }
  }

  &__tabs {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 0;
    margin-bottom: 16px;
    position: sticky;
    top: 0;
    background: var(--neutral-bg-default-active, #FFF);
    z-index: 101;
    border-bottom: 2px solid var(--primary-stroke-weak-active, #F0F0F0);

    &-item {
      display: flex;
      align-items: center;
      height: 32px;
      padding: 0 8px;
      gap: 4px;
      user-select: none;
      cursor: pointer;
      margin-bottom: -2px;

      &--is-active {
        border-bottom: 2px solid var(--primary-stroke-weak-active, #003C3C);
      }

      .icon {
        stroke: #191414;
      }
    }
  }

  &__tab-placeholder {
    height: calc(100% - 64px);
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    padding: 16px;
    border-top: 1px solid #F0F0F0;

    .button {

      .icon svg {
        stroke: unset;
        fill: #fff;
      }
    }

    .audit-log-button {
      .icon svg path {
        stroke: $black;
      }
    }
  }

  &__updater {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
</style>
